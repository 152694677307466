import React, { FunctionComponent } from 'react'
import styled from "styled-components"
import Layout from "components/Layout"

interface Props {}

const PresentationContainer = styled.section`
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const PresentationPage: FunctionComponent<Props> = () => {
  return (
    <Layout>
      <PresentationContainer>
        <iframe
          src="https://mobioos.sharepoint.com/sites/SalesDevelopment/_layouts/15/Doc.aspx?sourcedoc={a786584b-9045-4861-acc4-ccb239d66d06}&amp;action=embedview&amp;wdAr=1.7777777777777777"
          frameBorder="0">
          Ceci est un document <a target="_blank" href="https://office.com">Microsoft
          Office</a> incorporé, avec <a target="_blank" href="https://office.com/webapps">Office</a>.
        </iframe>
      </PresentationContainer>
    </Layout>
  )
}

export default PresentationPage
